import React from 'react'
import styled from 'styled-components'
import { htmlParser } from '../../../../../../../../modules/parse'
import { translateFontSizeToTheme } from '../../../../../../../../modules/font'
import AdUnit from '../../../../../../../../components/AdUnit'

const ContentWrapper = styled.div`
  position: relative;
  color: ${(props) => (props.inverted ? props.theme.color.textInverted : 'rgba(0, 0, 0, .7)')};

  h4 {
    font-size: 24px;
    margin: 23px 0;
    @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      margin: 20px 0;
    }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      font-size: 20px;
      margin: 21px 0;
    }
  }

  h5 {
    font-size: 22px;
    margin: 25px 0;
    @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      margin: 20px 0;
    }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      font-size: 18px;
      margin: 22px 0;
    }
  }

  h6 {
    font-size: 20px;
    margin: 28px 0;
    @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      margin: 20px 0;
    }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      font-size: 17px;
      margin: 25px 0;
    }
  }

  a {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.text)};
    @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.text)};
    }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.text)};
    }

    &:hover {
      opacity: 0.7;
    }
  }

  h4 + br {
    display: none;
  }

  h5 + br {
    display: none;
  }

  h6 + br {
    display: none;
  }

  div.img-inline.inline-file + br {
    display: none;
  }

  .embed-box {
    display: inline-block;
    width: 100%;
  }
`

const StyledMobileAdUnit = styled(AdUnit)`
  > div {
    display: flex;
  }
  > div > div {
    width: calc(100% + ${(props) => props.theme.sizes.gapEdge} + ${(props) => props.theme.sizes.gapEdge});
    justify-content: center;
    margin: 20px 0 0;
    text-align: center;
  }
`

const StyledAdUnit = styled(AdUnit)`
  > div > div {
    float: right;
    margin-left: ${(props) => props.theme.sizes.gapVerticalHalf};
    margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
    @media (min-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      margin-right: -169px;
    }
  }
`
const StyledCenteredAdUnit = styled(AdUnit)`
  > div > div {
    margin-left: ${(props) => props.theme.sizes.gapVerticalHalf};
    margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
    @media (min-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      margin-right: -169px;
    }
  }
`

const StyledMaxHeightAdUnit = styled(StyledAdUnit)`
  max-height: 400px;
`
const StyledMaxHeightCenteredAdUnit = styled(StyledCenteredAdUnit)`
  max-height: 400px;
`

const StyledMaxHeightMobileAdUnit = styled(StyledMobileAdUnit)`
  max-height: 400px;
`

const StyledEditor = styled.p`
  margin: 0;
  padding-top: 13px;
  padding-bottom: 26px;
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textSecondary};

  font-size: calc(${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.text)} - 3px);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: calc(${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.text)} - 3px);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: calc(${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.text)} - 3px);
  }
`

const ContentDefault = ({
  embeds,
  editor,
  articleLayout,
  smartAd,
  adTargeting,
  inverted,
  html,
  fontSize,
  showAds,
  tags,
  ...props
}) => {
  const parserOptions = {
    embeds,
    fontSize: fontSize,
    dropImageAttribs: true,
    articleLayout,
    adComponents: {
      desktop: <StyledMaxHeightAdUnit adId="S5" mode="tablet_desktop" />,
      mobile: <StyledMaxHeightMobileAdUnit adId="S5" mode="mobile" />,
      desktopCentered: <StyledMaxHeightCenteredAdUnit adId="S5" mode="tablet_desktop" />,
      showAds: showAds,
      smartAd: smartAd,
      targeting: adTargeting,
    },
  }

  return (
    <>
      <ContentWrapper id="articleContent" inverted={inverted} fontSize={fontSize} {...props}>
        {htmlParser(html, parserOptions, tags)}
      </ContentWrapper>
      {editor && <StyledEditor fontSize={fontSize}>{editor}</StyledEditor>}
    </>
  )
}

export default ContentDefault
